

.container {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 30px auto;
    flex-direction: column;
}

.search-bar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.search-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 700px;
}

.search-bar {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 25px 0 0 25px;
    font-size: 1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, border-color 0.3s;
}

.search-bar:focus {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    outline: none;
}

.search-button {
    padding: 10px 20px;
    border: none;
    border-radius: 0 25px 25px 0;
    background-color: #007bff;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card {
    display: block;
    width: 300px;
    text-decoration: none;
    color: #333;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card-image {
    width: 100%;
    height: auto;
    display: block;
}

.card-content {
    padding: 20px;
    background-color: #fff;
}

.card-title {
    font-size: 1.5em;
    margin: 0 0 10px;
}

.card-description {
    font-size: 1em;
    color: #666;
}
